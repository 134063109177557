import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import Container from './container'
import * as styles from './quote.module.css'

const Quote = ({ image, quote }) => {
  if (!image) return null
  if (!quote) return null

  return (
    <Container>
        <div className={styles.row}>
            <div className={styles.column}>
                <GatsbyImage alt="" image={image} />
            </div>
            <div className={styles.column}>
                <div className={styles.quoteColumn}>
                    <div className={styles.quote}>{quote}</div>
                </div>
            </div>
        </div>
    </Container>
  )
}

export default Quote
