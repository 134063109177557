import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import Hero from '../components/hero'
import Introduction from '../components/introduction'
import ArticlesPreview from '../components/articles-preview'
import FrontpageHeader from '../components/frontpage-header'
import Quote from '../components/quote'
import Seo from '../components/seo'



class RootIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.nodes')
    const [siteInfo] = get(this, 'props.data.allContentfulSiteInfo.nodes')

    return (
      <Layout location={this.props.location}>
        <Seo
          title="Makers Inc."
          description="Blogs voor mensen die graag kleding naaien"
        />
        <Hero
        image={siteInfo.frontPageImage.gatsbyImageData}
        title={siteInfo.title}
        content={siteInfo.description}
        />
        <Introduction intro_text={siteInfo}/>
        <Quote image={siteInfo.quoteImage.gatsbyImageData} quote={siteInfo.quoteText} />
        <FrontpageHeader text={"BLOG POSTS"} />
        <ArticlesPreview posts={posts} /> 
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(limit: 6, sort: { fields: [publishDate], order: DESC }, filter: {blogType: {contentful_id: {ne: "1tyvUNNgUE5UpvDNW7DXgB"}}}) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        blogCategory {
          category
        }
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulSiteInfo(
      filter: { contentful_id: { eq: "SYJXpMEX8IfUNLU5gBqae" } }
    ) {
      nodes {
        title
        description
        frontPageImage {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            width: 1180
            quality: 100
          )
        }
        introductionText
        introductionTitle
        introductionCloser
        quoteText
        quoteImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 900
            quality: 100
          )
        }
      }
    }
  }
`
