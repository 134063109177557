import React from 'react'

import * as styles from './introduction.module.css'

const Introduction = ({ intro_text }) => {
  if (!intro_text) return null

  return (
    <div className={styles.container}>
      <div className={styles.background}>
          <div className={styles.textcontainer}>
              <div className={styles.title}>{intro_text.introductionTitle}</div>
              <div
              dangerouslySetInnerHTML={{
                __html: intro_text.introductionText,
              }}
            />
              <div className={styles.closer}>{intro_text.introductionCloser}</div>
          </div>
      </div>
    </div>
  )
}

export default Introduction
