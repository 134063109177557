import React from 'react'
import Container from './container'



const FrontpageHeader = ({ text }) => {
   return (
       <Container>
           <div>
                <h2>{text}</h2>
           </div>
       </Container>
   );
}

export default FrontpageHeader